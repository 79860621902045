html {
  font-size: 13px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: 13px !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  /* font-size: 13px !important; */
}

input {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  /* font-size: 13px !important; */
}

input[type="number"] {
  text-align: right;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}

.content {
  padding: "10px";
  padding-top: 30;
  -webkit-animation: fadeIn ease 2s;
  animation: fadeIn ease 2s;
  width: inherit;
}

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.MuiOutlinedInput-input {
  padding: 12px 14px !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #558c3b;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-track:hover {
  background-color: #888;
}

.botonHerramienta {
  margin: 5px auto;
  width: 100%;
}

.boton {
  background-color: #558c3b !important;
  border-color: #558c3b !important;
}

.boton:hover {
  background-color: #30592a !important;
  border-color: #30592a !important;
}

.boton:active {
  background-color: #30592a !important;
  border-color: #30592a !important;
}

.boton:focus {
  background-color: #30592a !important;
  border-color: #30592a !important;
}

.enlace {
  margin: 0;
  color: #558c3b !important;
  text-decoration: none !important;
}

.enlace:hover {
  color: #30592a !important;
}

.colorFondo {
  background-color: #ffffff;
}

.inputs {
  border-color: #30592a !important;
}

.impresionOculta {
  display: none;
}

@media print {
  .impresionOculta {
    display: initial;
  }

  input::placeholder {
    opacity: 0 !important;
  }
}

button.MuiButton-textPrimary {
  color: #000000;
}
